.formsimple-selectmultiple{
	&::after {	
		position: absolute;
		top: 2px;
		right: 15px;
		pointer-events:none;
		bottom: 0;
		display: block;
		z-index: 1;
		text-align: center;
		width: 1rem;
		height: 0rem;
		line-height: #{$formsimple_inputHeight - ($formsimple_inputBorder * 2)}px;
		content: "\f078";
		font-size: 17px !important;
		color: $colorPrimary;
		font-family: 'Objetiva Software Light' !important;
		transform: rotate(0deg);
		transform-origin: center;
		transition: transform 0.15s ease;
	}

	& .label {
		background-color: $gray5;
		color: $gray1;
		font-size: .9rem !important;
		height: 39px !important;
		font-weight: 500 !important;
	}
	& .component {
		border-top: none;
		border-bottom: 1px solid white !important;
		border-left: 1px solid white !important;
		border-right: 1px solid white !important;

		& .botoes{
			& .undo{
				background-color: $colorPrimary;
				border: 0 !important;
				border-color: $colorPrimary !important;
				color: white !important;
				font-weight: 900;
				cursor: pointer;
				&:hover{
					background-color: darken($colorPrimary,8);
				}

			}
			& .ok{
				background-color: $colorPrimary;
				color: white !important;
				font-weight: 900;
				cursor: pointer;
				&:hover{
					background-color: darken($colorPrimary,8);
				}
			}

			& button {
				display: block;
				margin: 0;
				outline: none;
				border: 0;
				background-color: transparent;
				height: inherit;
			}
		}
	}
}