.imovel-ver-titulo {
	display: block;
	width: 100%;
	
	& .tituloPagInterna {
		box-sizing: border-box;
		padding-right: 5rem;

		& h1 {
			font-size: 1.25rem;
			font-weight: 500;
			color: $colorSecondary;
			margin: 0 0 1rem 0;
		}

		& .imoEnderecoInterna {
			display: block;
			width: 100%;
			font-size: .9rem;
			font-weight: 500;

			& img {
				width: 1.25rem;
				display: inline-block;
				vertical-align: middle;
			}
		}

		& .crumpInterna {
			font-size: .8rem;
			font-weight: 400;
			margin: 1rem 0;
		}
	}
}

@media (max-width: 1023px) {
	.imovel-ver-titulo {
		& .tituloPagInterna {
			padding-right: 0;
	
			& h1 {
				font-size: 1.1rem;
				line-height: 1.35rem;
			}
		}
	}
}