.imovel-ver-titulo {

	& .valor {
		text-align: right;
	}
}


@media all and (max-width: 1023px){
	.imovel-ver-titulo {

		& .valor {
			text-align: left;
		}

		& .tituloPagInterna {
			font-size: 1.1rem !important;
			line-height: 1.35rem !important;
		}
	}
}