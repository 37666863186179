.nav {
	display: block;
	width: 25vw;
	position: fixed;
	top: 0;
	right: -1000px;
	background: rgba($colorPrimary,0.9);
	z-index: 99991;
	color: white;
	overflow: auto;

	& button {
		margin-right: 1rem;

		& i {
			line-height: inherit;
		}
	}

	& .nav-flow {
		min-height: 50vh;
		height: 100vh;
	}

	& .nav-titulo {
		color: white;
		font-size: 1.7rem;
		font-weight: 600;
		padding: 1.5rem 1rem;
		@include border-box;
		text-transform: uppercase;
	}

	& .nav-menu {
		color: white;
		font-size: 1.4rem;
		margin: 0;
		padding: .6rem 1rem;
		@include border-box;

		&:hover {
			font-weight: 500;
			color: $gray2;
			background-color: white;
		}
	}
}

@media all and (max-width: 1023px){
	.nav{
		width: 100%;
		& .content {
			padding-bottom: 10rem !important;
		}
	}
}

.colunas-pai{
	display: table;
}

.colunas-colunas {
	display: table-cell;
	width: 50%;
	
	background-image: url('/jnh/popup/popup.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}
@media all and (max-width: 1024px){
	.colunas-colunas{
		width: 100%;
	}
}