.imovel-ver-contato {
	display: block;
	width: 100%;
	background-color: lighten($gray5,1);

	& h2 {
		display: block;
		width: 100%;
		background-color: $colorSecondary;
		padding: 1rem;
		margin-top: 0;
		@include border-box;
		color: $gray1;
	}

	// DIV FORMULÁRIO
	& .imovel-ver-corretor {
		display: block;
		width: 100%;
		padding: 0 1rem;
		@include border-box;

		& .imovel-ver-corretor-img {
			display: inline-block;
			width: 4rem;
			vertical-align: middle;

			& img {
				@include border-radius(50%);
				display: block;
				width: 100%;
				user-select: none;
			}
		}

		& .imovel-ver-corretor-detalhes {
			display: inline-block;
			width: calc(100% - 4rem);
			vertical-align: middle;
			padding-left: 1rem;
			@include border-box;

			& h3 {
				color: $gray1;
			}

			& img {
				display: inline-block;
				width: 1.7rem;
				vertical-align: middle;
			}
		}
	}
	
	// DIV FORMULÁRIO
	& .imovel-ver-formulario {
		display: block;
		width: 100%;
	}
}

.form-contato-fix {
	position: fixed;
	width: calc(25% - 6rem);
	top: 20px;
	z-index: 10;
	@include box-shadow('0 4px 11px -2px rgba(0,0,0, .8)');
	transition: box-shadow 1s;
}

@media all and (min-width: 1023px){

	.form-contato-fix {
		width: calc(25% - 3rem);
	}
}