.container_servicos_index {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 1.5rem;
	
	& .box-home{
		text-align: center;
		width: 100%;
		display: block;
		border-radius: 10px;
		padding: 2rem 1.5rem;
		@include border-box;
		background-color: white;
		box-shadow: 0 5px 10px 2px rgba(black, 0.2);
		transition: all .1s ease-in-out;

		&:hover{
			transform: scale(1.03);
			box-shadow: 0 5px 5px 1px rgba(black, 0.2);
		}
	}
}


.buttonsections{
	background-color: $colorTerciary !important;
	border: none !important;
	border-radius: 5px !important;
	&:hover{
		border: none !important;
		background-color: darken($colorTerciary,5) !important;
	}

}


#ux_to_top{
	position: relative;
	top: -100px;
	left: -8%;
	z-index: 91;
}

.pd-center-inta{
	padding: .5rem;
	@include border-box;
}

.h1position{
	position: absolute;
	top: 50%;
	z-index: -1;
}

.bggraycorretores{
	background-color: #9b93ab;
}
.imagemcorretor{
	width: 40%;
	position: absolute;
	bottom: 0;
	
}
.imagemcorretor2{
	width: 38%;
	position: absolute;
	bottom: 0;
	right: 0;
}
.imagemcorretor3{
	width: 38%;
	position: absolute;
	bottom: 0;
	right: 0;
}


.miniatura_rural {
	display: block;
	width: 100%;
	position: relative;
	line-height: 0;

	@include transition('15s all');
	& img {
		filter: brightness(80%);
	}
	
	&:hover, &:focus{

		& img {
			filter: brightness(100%);
		}
	}
	& .texto-rural{
		display: block;
		width: auto;
		position: absolute;
		bottom: 6rem;
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;

		font-weight: 600;
		color: white;
		font-size: 1.7rem;
		z-index: 1;
	}

	& .texto-rural2{
		display: block;
		width: auto;
		position: absolute;
		bottom: 4rem;
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;

		font-weight: 400;
		color: white;
		font-size: 1.1rem;
		z-index: 1;
	}
}

@media all and (min-width: 480px) and (max-width: 1023px){
	.widthbox{
		width: 50% !important;
	}
	
	.imagemcorretor{
		right: -10px;
		width: 13rem;
	}
	.imagemcorretor2{
		width: 14rem;
		right: -10px;
	}
	.imagemcorretor3{
		width: 13.4rem;
		right: -10px;
	}
}

.bg-line{
	border-bottom: solid 1px white;
}
.servicos {
	display: block;
	width: 100%;
	padding: 5rem 1.5rem;
	@include border-box;
	cursor: pointer;
	& p {
		color: #ffffff;
	}

	&:hover{
		background-color: darken($colorQuarterly, 5);
		transition: all 0.15s;
	}
	
	& .servicos-img {
		display: block;
		width: 5rem;
		user-select: none;
		
		& img {
			display: block;
			width: 100%;
		}
	}
}

.input-novidades:-webkit-autofill,
.input-novidades:-webkit-autofill:hover, 
.input-novidades:-webkit-autofill:focus, 
.input-novidades:-webkit-autofill:active  {
    -webkit-box-shadow:0 0 0 50px rgba(0,0,0,0) inset !important;
    -webkit-text-fill-color: white !important;
}

@media all and (max-width: 482px){
	.pd-left-4-mob{
		& .pd-left-4{
			padding-left: 0 !important;
		}
	}
	.widthbox{
		width: 100% !important;
	}
}

@media all and (min-width: 1024px) and (max-width: 1200px){
	.imagemcorretor{
		right: -10px;
		width: 12rem;
	}
	.imagemcorretor2{
		width: 12rem;
		right: -10px;
	}
	.imagemcorretor3{
		width: 12rem;
		right: -10px;
	}
}

.sizecorretor{
	height: 100%;
}

.sizecorretorson{
	height: 100%;
}

@media all and (max-width: 480px){
	.spacecorretor{
		display: none;
	}
	.imagemcorretor{
		right: -10px;
		width: 11rem;
	}
	.imagemcorretor2{
		width: 12rem;
		right: -10px;
	}
	.imagemcorretor3{
		width: 11rem;
		right: -10px;
	}
	
}


@media all and (min-width: 1024px){
	.heightcorrt{
		height: 24rem;
	}
}

@media all and (max-width: 1023px){
	.width-box-mob{
		width: 50%;
	}

	.container_servicos_index {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1.5rem;

		& .box-home{
			padding: 2.5rem 0;
		}
	}
	
}