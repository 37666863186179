/*
	GALERIA
	VÍDEOS
 */
 .ui-gallery-canvas-img{
	 & iframe {
		 height: 100% !important;
		 width: 100% !important;
	 }
 }

 .modal {
    display: none;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0, 0, 0, 0.842);
}

.modal-content {
    background-color: #fefefe;
    margin: 2rem auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    text-align: center;
}

.close {
	padding: 7px;
	border-radius: 50%;
	margin: 15px 15px 0 0;
    color: #fff;
    float: right;
    font-size: 3rem;
    font-weight: 400;
}

.close:hover,
.close:focus {
    color: #d64833;
    text-decoration: none;
    cursor: pointer;
}

.galleria {
    display: grid;
	grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
	gap: 5px;
    justify-content: center;
}


.galleria img {
    max-width: 100%;
    cursor: pointer;
    
}

.img_galeria {
	margin: 0;
	width: 100%;
	height: 350px;
}

.img_galeria img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

/* .galleria img:hover {
    transform: scale(1.05);
} */
.contador_imagens {
	display: none;
}

.legenda_img {
	display: none;
}

.contato-imovel {
	padding: 1rem 0;
	width: auto;
	
	& h4 {
		font-size: 1.25rem;
		color: #000;
	}

	& button {
		border: none;
		background-color: #d64833;
		color: #fff;
		padding: .5rem .8rem;
	}
}

@media (max-width: 1260px) {
	.img_galeria {
		margin: 0;
		width: 100%;
		height: 250px;
	}
}

@media (max-width: 1023px) {
	.img_galeria {
		margin: 0;
		width: 100%;
		height: 200px;
	}
}

@media (max-width: 824px) {

	.galleria {
		display: grid;
		grid-template-columns: 1fr 1fr;
		flex-wrap: wrap;
		gap: 5px;
		justify-content: center;
	}

	.img_galeria {
		margin: 0;
		width: 100%;
		height: 200px;
	}
}