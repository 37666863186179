.headerindex {
	height: calc(90vh + 1px);
	min-height: 40rem;
	display: block;
	width: 0;
	padding: 0;
	@include border-box;
	z-index: 2;
	/* & .imgarea{
		width: 100%;
		height: 100%;
		background-image: url('/jnh/header/bg-header-01.png');
		background-size: cover;
	} */
}

.headerinterno {
	height: 5rem;
	z-index: 1;
}
.busca-top{
	width: 100%;
	background-color: $colorPrimary;
	height: 2rem;
}

.column-header{
	padding: 2.6% 0;
	padding-left: 10px;
	&:hover {
		background-color: lighten($gray4,17);
	}
}

.column-header2{
	padding: 2.6% 0;
	padding-left: 15px;
	background-color: lighten($gray4,14);
	
}

.column-header3{
	padding: 2.52% 0;
	padding-left: 50px;
	background-color: $colorPrimary;
}

.shadow-box{
	// @include filter('drop-shadow(0 2px 6px rgba(0,0,0,.3))');
	@include box-shadow('0 0 20px 5px rgba(0,0,0,.2)');
}
.shadow-box2{
	// @include filter('drop-shadow(0 2px 6px rgba(0,0,0,.3))');
	@include box-shadow('0 9px 14px 6px rgba(0,0,0,.2)');
}

.logoheaderpc{
	border-top: solid 1px $colorPrimary;
}
.btn-home{
	height: 39px !important;
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
}

$largura_menubtn: 3;
$largura_logohdr: 11;

.logo-mobile{
	border-top: solid 1px $colorPrimary;
	width: 100%;
	& img{
		width: 100%;
	}
}

.bg-oq-mob{
	background-color: $colorPrimary;
	height: 18px !important;
	color: white;
	text-align: center;
	font-size: 1rem;
	width: 90%;
	padding-top: 0.6rem;
	padding-bottom: 0.1rem;
	font-weight: 600;
	border-radius: 5px;
}


.headerindx{
	& .logohdr{
		display: inline-block;
		vertical-align: middle;
		width: #{$largura_logohdr}rem;
		border-top: solid 1px $colorPrimary;
		& img{
			width: 100%;
		}
	}
	& .buscahdr{
		display: inline-block;
		vertical-align: middle;
		width: calc(100% - #{($largura_logohdr + ($largura_menubtn + 5))}rem);


		& .bg-oq{
			background-color: $colorPrimary;
			height: 39px !important;
			color: white;
			text-align: center;
			font-size: .9rem;
			padding-top: 0.75rem;
			font-weight: 600;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			margin: .15rem 0 .15rem 0;
		}
	}
	& .menubtn{
		width: #{$largura_menubtn}rem;
		display: inline-block;
		vertical-align: middle;

		& i {
			font-size: 2.5rem !important;
		}
	}
}
.headerpc {
	display: block;
	width: 100%;
	position: relative;
	margin-bottom: -1.4rem;
	& .superior {
		display: block;
		width: 100%;
		height: 4.7rem;
		color: #ffffff;
		z-index: 3;
		vertical-align: middle;
		
		& .logo {
			display: inline-block;
			margin-left: 0;
			width: 12%;
			padding: 1.5rem;
			padding-left: 0;
			@include border-box;
			vertical-align: middle;

			& img {
				display: block;
				width: 100%;
			}
		}

		/* GRUPO HEADER */

		$largura_share: 5;
		$largura_menu: 4;
		$largura_contact: 17;
		$diferenca: 11;
		& .share {
				display: inline-block;
				vertical-align: middle;
				width: #{$largura_share}rem;
			& .item{
				display: inline-block;
				vertical-align: middle;
				padding: 0.5rem;
				width: 1.5rem;
				& img{
					width: 100%;
				}
			}
		}
		& .menu {
			text-align: right;
			display: inline-block;
			width: #{$largura_menu}rem;
			vertical-align: middle;
		}

		& .sessions {
			display: inline-block;
			width: calc(100% - #{(($largura_share + $largura_menu + $largura_contact) + $diferenca)}rem);
			vertical-align: middle;

			& .central{
				display: inline-block;
				vertical-align: middle;
				width: 30%;
				&:hover{
					background-color: $colorPrimary !important;
					& p{
						color: white !important;
					}
				}
			}

			& .itemsession{
				display: inline-block;
				vertical-align: middle;
				width: 30%;
				padding: 0.5rem 0.8rem;
				

				& .a{
					display: block;
					width: 100%;
					font-size: 1rem;
					color: white;
					line-height: 2.3rem;
					font-weight: 600;
					padding: 0 0.9rem;
					@include border-box;

					&:hover{
						font-weight: 700;
					}
				}
				
				& i{
					padding-left: 0.4rem;
				}

				&:hover .sub{
					display: block;
				}

				&:hover .bg{
					background-color: $colorPrimary;
					color: white !important;
				}

				&:hover .a{
					font-weight: 600;
				}


				& .sub{
					display: none;
					width: 18%;
					position: absolute;
					text-align: left;
					z-index: 9999;
					overflow: auto;
					max-height: calc(100vh - 10rem);

					& .link{
						display: block;
						width: 65%;
						background-color: white;
						color: $gray2 !important;
						padding: 0.1rem 0.8rem;
						font-size: 1rem !important;
						line-height: 2.3rem;
						transition: .15s all;
						@include border-box;

						& i{
							color: $gray1;
						}

						&:hover{
							background-color: $colorPrimary;
							color: white !important;
							font-weight: 900;
							padding-left: 1rem;
						}

						&:hover i{
							color: white;
						}
					}
				}
			}
		}
		& .contact{
			display: inline-block;
			width: #{$largura_contact}rem;
			vertical-align: middle;
			& .mg-tx{
				margin-bottom: 1rem !important;
				margin-top: 0.1rem !important;
			}
			& .mg-tx2{
				margin-bottom: 0 !important;
				margin-top: 0 !important;
			}
		}

	}

	& .busca {

		display: block;
		position: absolute;
		width: 70%;
		top: 45%;
		z-index: 3;
		right: 50%;
		transform: translate(50%, -50%);
		text-align: center;

		& h2 {
			color: #ffffff;
			font-size: 2rem;
		}
	}
}

.container-bg-headerpc{
	display: block;
	position: absolute;
	top:0;
	overflow: hidden;
	z-index: -1;
	height: 100%;
	width: 100%;
}

.videoArea {
	z-index: 1;
	display: block;
	position: relative;
	width: 100%;
	height: calc(100vh - 7.3rem);


	& .videoareaareaoverlay {
		display: block;
		z-index: 3;
		background-color: rgba(black, .4);
		position: absolute;
		width: 100%;
		height: 100%;
	}
	& .videoareaarea {
		display: block;
		position: absolute;
		width: 100%;
		z-index: 1;
		height: 100%;
		pointer-events: none;
		overflow: hidden;

		& video {
			object-fit: cover;
			width: 100vw;
			z-index: 1;
			height: 100vh;
			display: inline-block;
			vertical-align: baseline;
		}
	}
}
.bg-headerpc{
	display: block;
	height: calc(90vh + 1px);
	min-height: 40rem;
	width: 100%;
	/* will-change: background-image; */
	background-image: url('/jnh/header/bg-header-01.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	/* animation-name: bgheaderpc;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 20s;
	animation-direction: normal; */
}
/* @include keyframes('bgheaderpc'){
	0% {
		background-image: url('/jnh/header/bg-header-01.jpg');
	}
	10% {
		background-image: url('/jnh/header/bg-header-01.jpg');
	}
	30% {
		transform: scale(1.03);
		background-image: url('/jnh/header/bg-header-01.jpg');
	}
	40% {
		transform: scale(1.03);
		background-image: url('/jnh/header/bg-header-02.jpg');
	}
	60% {
		transform: scale(1);
		background-image: url('/jnh/header/bg-header-02.jpg');
	}
	70% {
		transform: scale(1);
		background-image: url('/jnh/header/bg-header-01.jpg');
	}
	100% {
		transform: scale(1.03);
		 background-image: url('/jnh/header/bg-header-01.jpg');
	}
} */

.bg-headerinterno {
	background-color: darken($colorPrimary, 5);
}

.headermobile-btn{
	position: absolute;
	width: 50%;
	top: 45%;
	right: 40%;
	transform: translate(40%, -40%);
}
.container-bg-headermobile{
	display: block;
	position: absolute;
	top: 0;
	overflow: hidden;
	z-index: -1;
	height: 100%;
	width: 100%;
}
.headermobile {
	display: block;
	width: 100%;
	height: calc(100vh - 65px);
	min-height: calc(100vh - 65px);
	position: relative;
	background-color: $gray4 !important;
	will-change: background-image;
	background-image: url('/jnh/header/bg-header-01-mobile.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center left;
	/* animation-name: headermobile;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 30s;
	animation-direction: normal; */
}
/* @include keyframes('headermobile'){
	0% {
		transform: scale(1);
		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
	}
	30% {
		transform: scale(1.02);
		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
	}
	40% {
		transform: scale(1.02);
		background-image: url('/jnh/header/bg-header-02-mobile.jpg');
	}
	60% {
		transform: scale(1);
		background-image: url('/jnh/header/bg-header-02-mobile.jpg');
	}
	70% {
		transform: scale(1);
		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
	}
	100% {
		transform: scale(1.02);
		background-image: url('/jnh/header/bg-header-01-mobile.jpg');
	}
} */

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media all and (max-width: 1024px){
	.headerpc{
		& .logo{
			padding: 0.3rem 0 0 0 !important;
		}
	}
	.pd-ajuste{
		padding-left: 0 !important;
	}
}

@media all and (max-width:480px){
	.headermobile-btn{
		width: 90% !important;
		padding-left: 0.8rem;
	}
}

@media all and (min-width: 1024px) and (max-width: 1220px){
	.mediawidth7{
		width: 15% !important;
	}
	.mediawidth3 {
		width: 15% !important;
	}
	.mediawidth2{
		width: 16% !important;
	}

	.column-header3{
		padding-left: 0 !important;
		padding: 2.7% 0 !important;
	}
	.column-header2{
		padding: 2.9% 0 !important;
		padding-left: 14px !important;
		background-color: lighten($gray4,14);
	}
}