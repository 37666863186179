.miniatura-imovel2 {
	display: inline-block;
	vertical-align: top;
	
	& .miniatura-imovel-img{
		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 75%;
		background-color: $gray5;
		position: relative;
		user-select: none;
		border: solid 1px $gray6;
		border-bottom: none;
		box-sizing: border-box;
		border-radius: 7px 7px 0 0;

		& img{
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			object-fit: cover;
		}

		& .miniatura-arrow{
			display: block;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			padding: 0;
			@include border-box;

			& button {
				display: block;
				line-height: 3rem;
				width: 3rem;
				border: none;
				padding: 0;
				margin: 0;
				outline: none;
				cursor: pointer;
				font-size: 2rem;
			}
		}

		& .arrow-left{
			left: 0;
		}
		& .arrow-right{
			right: 0;
		}
	}
	
	& .miniatura-imovel-img-video {
		@extend .miniatura-imovel-img;

		& .miniatura-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			font-size: 4rem;
			color: #ffffff;
		}
	}

	& .miniatura-informacoes {
		text-align: center;
		padding: 0 .5rem;
		@include border-box;

		& .left {
			color: $gray1;
			font-size: 1.2rem;
		}
		& .right {
			color: $gray1;
			font-size: .8rem;
			letter-spacing: -.09rem;
    		font-weight: 300;
		}
	}

	& .miniatura-cidade-estado{
		display: inline-block;
		width: calc(100% - 3rem);
		height: 1.3rem;
		overflow: hidden;
		vertical-align: middle;
		text-transform: uppercase;
		margin-bottom: .5rem;

		font-size: .9rem;
		font-weight: 600;
		color: $gray1;

		& i {
			font-size: .8rem;
			color: $colorPrimary;
		}
	}
	& .miniatura-acao-tipo{
		font-size: .9rem;
		font-weight: 500;
		color: $gray2;
	}

	& .miniatura-container-id{
		display: inline-block;
		width: 5rem;
		vertical-align: middle;
		text-align: right;
	}

	& .miniatura-imovel-endereco {
		font-style: oblique;
	}

	& .miniatura-imovel-preco {
		padding: .5rem 0;
		@include border-box;
		background-color: $colorPrimary;
		font-size: 1rem;
		width: 100%;
		display: block;
		text-align: center;
		border-radius: .2rem;

	}

	& .miniatura-p {

		color: $gray2 !important;
		font-weight: 600;
		font-size: 1rem;
	}

	& .miniatura-imovel-detalhe{
		display: block;
		width: 100%;
		padding: 1rem;
		background-color: white;
		border: solid 1px $gray6;
		border-radius: 0px 0px 7px 7px;
		@include border-box;
		position: relative;

		& .miniatura-btn-favorito {
			position: absolute;
			top: 1rem;
			right: 1rem;
		}

		& .miniatura-imovel-btns-icons{
			display: block;
			width: 100%;
			overflow: hidden;
			min-height: 22px;
			height: 2.4rem;

			& .miniatura-imovel-icone {
				display: inline-block;
				width: 25%;
				vertical-align: middle;
			}

			& .miniatura-informacoes{

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}
	}
}
