/* FOOTER */
.bg-footer {
	display: block;
	width: 100%;
	background-color: $colorPrimary;

	& .footer-logo {
		margin-left: 0;
		display: block;
		width: 10rem;

		& img {
			display: block;
			width: 100%;
		}
	}

	& p {
		color: #ffffff;
	}
}
/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}