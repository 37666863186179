.imoveis-ver-sharefavorinota{

	& .imoveis-ver-nota, .imoveis-ver-share, .imoveis-ver-favorito {
		vertical-align: top;
		padding: 1rem;
		@include border-box;

		& .titulo {
			text-align: left;
		}

		& .descricao {
			text-align: left;
		}
	}

	& .b-favorito-active {
		color: $colorPrimary;
	}
}

@media all and (max-width: 1023px){
	.imoveis-ver-sharefavorinota {

		& .box-laterais-1 {
			margin: 0;
		}
	}
}