/* MINIATURA 1 */
.b-perso{
	border: solid 2px white !important;
	font-size: 1rem;
	&:hover, &:active, &:focus, &:visited{
		border: solid 2px white !important;
		color: white !important;
		font-size: 1rem;
	}
}
.miniatura-lancamento {
	display: inline-block;
	vertical-align: top;
	padding: 1rem;
	@include filter( grayscale(30%) );
	@include border-box;

	&:hover { 
		@include filter( grayscale(0%) ) 
	}

	&:hover .miniatura-lancamento-img img {
		//border: 1px solid $colorSecondary;
	    @include animation-duration(0.20s);
	    @include animation-name(lancamento_img);
	    @include animation-iteration-count(1);
	    @include animation-direction(normal);
	    animation-fill-mode: forwards;
	    animation-timing-function: ease-in-out;
	}


	& .miniatura-lancamento-img{

		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 130%;
		background-color: $gray1; 
		//border: 1px solid $colorSecondary;
		position: relative;
		cursor: pointer;

		& img{
			display: block;
			height: 120%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		& .miniatura-lancamento-status{
			top: 10px;
			left: 10px;
			display: block;
			position: absolute;
			background-color: $colorPrimary;
			padding: 5px 10px;
			color: #ffffff;
			font-size: 16px;
			@include border-box;
		}

		& .miniatura-lancamento-detalhe {
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 10px 0;
			@include border-box;
			background-color: rgba(0,0,0, .6);
			
			& .miniatura-lancamento-overflow {
				/*overflow: hidden;
				height: 40px;*/
			}
		}
	}
}

@include keyframes(lancamento_img){
	from{
		transform: translate(-50%, -50%) scale(1.0);
	}
	to{
		transform: translate(-50%, -50%) scale(1.06);
	}
}

.miniatura-lancamento-marker {
	display: inline-block;
	width: 20px;
	vertical-align: middle;
}