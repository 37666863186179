/* MINIATURA 1 */
/* .miniatura-imv-status{
	top: 20px;
	left: 0px;
	display: block;
	position: absolute;
	background-color: $colorSecondary;
	padding: 5px 10px;
	color: black;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	font-weight: 600;
	border-color:rgba(#ffffff, .5);
	font-size: 14px;
	@include border-box;
}
.linkg{
	&:hover{
		color: $colorPrimary !important;
	}
}
.miniatura-imovel {
	display: inline-block;
	vertical-align: top;

	& .miniatura-imovel-img{

		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 60%;
		background-color: $gray1; 
		position: relative;
		user-select: none;

		& img{
			display: block;
			width: 100%;
			position: absolute;
			left: 0;
			top: -30%;
		}

		& .miniatura-arrow{
			display: block;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			padding: 0;
			@include border-box;

			& button {
				display: block;
				line-height: 3rem;
				width: 3rem;
				border: none;
				padding: 0;
				margin: 0;
				outline: none;
				cursor: pointer;
				font-size: 2rem;
			}
		}

		& .arrow-left{
			left: 0;
		}
		& .arrow-right{
			right: 0;
		}
	}
	
	& .miniatura-imovel-img-video {
		@extend .miniatura-imovel-img;

		padding-top: 70%;
		position: relative;
		
		& img {
			top: -8px;
		}

		& .miniatura-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			font-size: 4rem;
			color: #ffffff;
		}
	}

	& .miniatura-informacoes {
		text-align: center;
		padding: 0 .5rem;
		@include border-box;

		& .left {
			color: $gray1;
			font-size: 1.2rem;
		}
		& .right {
			color: $gray1;
			font-size: .8rem;
			letter-spacing: -.09rem;
    		font-weight: 300;
		}
	}

	& .miniatura-cidade-estado{
		display: inline-block;
		width: calc(100% - 3rem);
		height: 1.3rem;
		overflow: hidden;
		vertical-align: middle;
		text-transform: uppercase;
		margin-bottom: .5rem;

		font-size: 1.1rem;
		font-weight: 700;
		color: $gray1;
	}
	& .miniatura-acao-tipo{
		font-size: 1rem;
		font-weight: 500;
		color: $gray2;
	}

	& .miniatura-container-id{
		display: inline-block;
		width: 5rem;
		vertical-align: middle;
		text-align: right;
	}

	& .miniatura-imovel-endereco {
		font-style: oblique;
	}

	& .miniatura-imovel-preco {
		padding: .5rem;
		@include border-box;
		background-color: $colorSecondary;
		font-size: 1.2rem;
	}

	& .miniatura-p {

		color: $gray2 !important;
		font-weight: 600;
		font-size: 1.1rem;
	}

	& .miniatura-imovel-detalhe{
		display: block;
		width: 100%;
		padding: 1rem;
		background-color: $gray5;
		border-radius: 0px 0px 7px 7px;
		@include border-box;
		position: relative;

		& .miniatura-btn-favorito {
			position: absolute;
			top: 1rem;
			right: 1rem;
		}

		& .miniatura-imovel-btns-icons{
			display: block;
			width: 100%;
			overflow: hidden;
			min-height: 22px;
			height: 2.4rem;

			& .miniatura-imovel-icone {
				display: inline-block;
				width: 25%;
				vertical-align: middle;
			}

			& .miniatura-informacoes{

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}
	}
} */

.cr {
	width: 10rem;
	padding: 9px;
	position: absolute;
	text-align: center;
	color: #f0f0f0;
  }
  
  .cr-sticky {
	position: absolute;
  }
  
  /* Positions */
  
  .cr-top    { top: 2rem; }
  .cr-bottom { bottom: 25px; }
  .cr-right  { left: -2.8rem; }
  
  /* Rotations */
  
  .cr-top.cr-left,
  .cr-bottom.cr-right {
	transform: rotate(-314deg);
  }
  
  .cr-top.cr-right,
  .cr-bottom.cr-left {
	transform: rotate(314deg);
  }
  
  /* Colors */
  
  
  .cr-primary    { background-color: $gray2; font-weight: 600; color:white;}
  
.clrmin{
	background-color: $colorPrimary;
	z-index: 3;
	color: white;
	font-size: 14px;
	position: absolute;
	top: 42px;
	font-weight: 600;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	padding: 5px 10px;
	right: 0;
}

.miniatura-imv-status{
	top: 15px;
	right: 0px;
	display: block;
	position: absolute;
	background-color: $colorPrimary;
	padding: 5px 10px;
	color: white;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	font-weight: 600;
	border-color:rgba(#ffffff, .5);
	font-size: 14px;
	@include border-box;
}

.miniatura-imv-tour{
	bottom: 13px;
	left: 0px;
	display: block;
	position: absolute;
	background-color: $colorPrimary;
	padding: 5px 10px;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	width: 8.5rem;
	border-color:rgba(#ffffff, .5);
	@include border-box;
	
	& .flex-tour {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& div {
			margin: 0;

			& img {
				height: 30px !important;
				width: 30px !important;
				position: unset !important;
			}
	
			& p {
				color: white;
				font-weight: 600;
				font-size: 14px;
				line-height: 0;
			}
		}
	}
}


$padraoborderRadius: 3px;
$padraoborderRadiusNumero: 3;


.miniatura-imovel {
	display: inline-block;
	vertical-align: top;

	& .miniatura-imovel-img{

		display: block;
		width: 99%;
		overflow: hidden;
		padding-top: 60%;
		background-color: white; 
		position: relative;
		user-select: none;
		border: .1rem solid white;
		@include border-radius('#{$padraoborderRadius} #{$padraoborderRadius} 0 0');
		@include box-shadow('0 -3px 10px 5px rgba(0,0,0,.1)');
		border-bottom: 0;
		& img{
			display: block;
			width: 100%;
			position: absolute;
			height: 100%;
			top: 0;
		}

		& .favmini > *{
			position: absolute;
			top: 5px;
			left: 5px;
			height: 2rem;
			z-index: 2;

			/* & .odiowqhd {
				position: absolute;
				top: 0;
			} */
		}
		& .miniatura-destaque {
			display: block;
			position: absolute;
			top: .5rem;
			left: .5rem;
			line-height: .95rem;
			font-size: 1rem;
			height: 1.5rem;
			background-color: $colorPrimary;
			padding: .3rem .5rem;
			font-weight: 500;
			@include border-radius($padraoborderRadius);
			@include border-box;
			color: #ffffff;
		}

		& .miniatura-arrow{
			display: block;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			padding: 0;
			@include border-box;

			& button {
				display: block;
				line-height: 3rem;
				width: 3rem;
				border: none;
				padding: 0;
				margin: 0;
				outline: none;
				cursor: pointer;
				font-size: 2rem;
			}
		}

		& .arrow-left{
			left: 0;
		}
		& .arrow-right{
			right: 0;
		}
	}
	
	& .miniatura-imovel-img-video {
	//	@extend .miniatura-imovel-img;

		padding-top: 70%;
		position: relative;
		
		& img {
			top: -8px;
		}

		& .miniatura-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			font-size: 4rem;
			color: #ffffff;
		}
	}




	& .miniatura-icone-label {
		display: block;
		width: 100%;
		overflow: hidden;
		font-size: 12px;
		height: 22.5px;
	}
	& .miniatura-container-icones {
		padding: 5px;
		@include border-box;
	}

	& .miniatura-icone-extends {
		padding: 2px;
		width: 25px;
		vertical-align: middle;
		text-align: center;

		& img {
			display: block;
			width: 100%;
		}
	}

	& .miniatura-icone-aa {
		border-right: 1px solid $gray3;
	}


	& .miniatura-icone-ab {
	}

	& .miniatura-icone-ac {
		border-left: 1px solid $gray3;
	}


	& .miniatura-icone-ba {
		border-right: 1px solid $gray3;
	}

	& .miniatura-icone-bb {
	}

	& .miniatura-icone-bc {
		border-left: 1px solid $gray3;
	}

	& .miniatura-border{
		border-left: 1px solid $gray3;
		border-right: 1px solid $gray3;
	}

	& .miniatura-border-all{
		display: block;
		width: 100%;
		height: 1px;
		background-color: $gray3;
	}

	& .miniatura-informacoes {
		text-align: center;
		padding: .5rem;
		@include border-box;

		& .left {
			color: $gray1;
			font-size: 1.2rem;
		}

		& .right {
			color: $gray1;
			font-size: .8rem;
			letter-spacing: -.09rem;
			font-weight: 300;
		}
	}

	& .miniatura-p {
		color: $gray1;
		font-size: 1.1rem;
	}

	& hr {
		margin: 0;
	}

	& .miniatura-icone-central {
		border-left: 1px solid $gray1;
		border-right: 1px solid $gray1;
	}

	& .miniatura-imovel-detalhe{
		display: block;
		width: 100%;
		padding: 1rem;
		min-height: 23.6rem;
		background-color: white;
		//padding-bottom: .4rem;
		//background-color: $gray5;
		border-radius: 0 0 #{$padraoborderRadius} #{$padraoborderRadius};
		@include border-box;
		border: .1rem solid #ffffff;
		border-top: 0;
		position: relative;
		@include box-shadow('0 0 10px 5px rgba(0,0,0,.1)');

		& .miniatural-imovel-preco {
			display: block;
			width: 100%;
			padding: .5rem;
			padding-top: .7rem;
			color: #ffffff;
			text-align: center;
			@include border-box;
			background-color: $colorTerciary;
			@include border-radius($padraoborderRadius);
			font-size: 1rem;

			&:hover, &:active, &:focus, &:visited{
				color: #ffffff;
			}
		}

		& .relative {
			
			& .miniatura-marker {
				display: inline-block;
				width: 1.7rem;
				vertical-align: middle;
				
				& img {
					display: block;
					width: 100%;
				}
			}
			
			& .miniatura-marker-after::after{
				display: block;
				position: absolute;
				left: .8rem;
				margin-top: 0;
				width: .1rem;
				bottom: -15%;
				height: 95%;
				background-color: $colorPrimary;
				content: '';
			}
			& .miniatura-marker-after::before{
				display: block;
				position: absolute;
				left: .6rem;
				width: .5rem;
				height: .5rem;
				@include border-radius(50%);
				bottom: -15%;
				background-color: $colorPrimary;
				content: '';
			}
		}
		
		& .miniatura-cidade-estado{
			display: inline-block;
			width: calc(100% - 2rem);
			vertical-align: middle;
			font-size: .9rem;
			overflow: hidden;
			height: .8rem;
			font-weight: 600;
			color: $gray1;
		}

		& .miniatura-descricao-simples {
			display: inline-block;
			width: calc(100% - 2rem);
			overflow: hidden;
			height: 2.8rem;
			font-weight: 400 !important;
			font-size: .95rem;
		}

		& .imovel-interno-btnfake-miniatura {
		//	@extend .imovel-interno-btnfake;
			background-color: $colorPrimary !important;
			padding: .2rem 1rem !important;
			padding-bottom: .1rem !important;
			margin: 0;

			&:hover {
				background-color: #ffffff !important;
				color: $gray1;
				border: 1px solid $colorPrimary;
			}
		}

		& .miniatura-container-btns {
			display: block;
			margin-left: 1.8rem;
			width: calc(100% - 2rem);
		}

		& .btn-maisinformacoes {
			text-transform: lowercase;
		    height: 1.6rem;
		    line-height: 1.5rem;
		    padding-bottom: 1.6rem;
			font-weight: 600;
			margin: 0;
			font-size: .73rem;
			padding-left: 1.2rem;
			padding-right: 1.2rem;
			@include border-radius(#{$padraoborderRadius});
			&:hover{
				margin: 0 !important;
				font-size: .73rem !important;
			    height: 1.6rem !important;
			    line-height: 1.5rem !important;
			    padding-bottom: 1.6rem !important;
			}
		}

		& .btn-contatar {
			text-transform: lowercase;
		    height: 1.6rem;
		    line-height: 1.5rem;
		    padding-bottom: 1.6rem;
			font-weight: 600;
			margin: 0;
			font-size: .73rem;
			padding-left: 1.2rem;
			padding-right: 1.2rem;
			@include border-radius(#{$padraoborderRadius});

			&:hover{
				margin: 0 !important;
				font-size: .73rem !important;
			    height: 1.6rem !important;
			    line-height: 1.5rem !important;
			    padding-bottom: 1.6rem !important;
			}
		}

		/*& .miniatura-btn-favorito {
			position: absolute;
			top: 1rem;
			right: 1rem;
		}

		& .miniatura-imovel-btns-icons{
			display: block;
			width: 100%;
			overflow: hidden;
			min-height: 22px;
			height: 2.4rem;

			& .miniatura-imovel-icone {
				display: inline-block;
				width: 25%;
				vertical-align: middle;
			}

			& .miniatura-informacoes{

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}*/
	}
}

.miniatura-imovel4 {
	display: inline-block;
	vertical-align: top;

	& .miniatura-imovel-img{

		display: block;
		width: 99%;
		overflow: hidden;
		padding-top: 60%;
		background-color: white; 
		position: relative;
		user-select: none;
		border: .1rem solid white;
		@include border-radius('#{$padraoborderRadius} #{$padraoborderRadius} 0 0');
		@include box-shadow('0 -3px 10px 5px rgba(0,0,0,.1)');
		border-bottom: 0;
		
		& img{
			display: block;
			width: 100%;
			position: absolute;
			left: 0;
			top: -30%;
		}
		& .favmini{
			position: absolute;
			top: 5px;
			left: 5px;
			z-index: 2;

			& .odiowqhd {
				position: absolute;
				top: 0;
			}
		}

		& .miniatura-destaque {
			display: block;
			position: absolute;
			top: .5rem;
			left: .5rem;
			line-height: .95rem;
			font-size: 1rem;
			height: 1.5rem;
			background-color: $colorPrimary;
			padding: .3rem .5rem;
			font-weight: 500;
			@include border-radius($padraoborderRadius);
			@include border-box;
			color: #ffffff;
		}

		& .miniatura-arrow{
			display: block;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			padding: 0;
			@include border-box;

			& button {
				display: block;
				line-height: 3rem;
				width: 3rem;
				border: none;
				padding: 0;
				margin: 0;
				outline: none;
				cursor: pointer;
				font-size: 2rem;
			}
		}

		& .arrow-left{
			left: 0;
		}
		& .arrow-right{
			right: 0;
		}
	}
	
	& .miniatura-imovel-img-video {
	//	@extend .miniatura-imovel-img;

		padding-top: 70%;
		position: relative;
		
		& img {
			top: -8px;
		}

		& .miniatura-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			font-size: 4rem;
			color: #ffffff;
		}
	}




	& .miniatura-icone-label {
		display: block;
		width: 100%;
		overflow: hidden;
		font-size: 12px;
		height: 22.5px;
	}
	& .miniatura-container-icones {
		padding: 5px;
		@include border-box;
	}

	& .miniatura-icone-extends {
		padding: 2px;
		width: 25px;
		vertical-align: middle;
		text-align: center;

		& img {
			display: block;
			width: 100%;
		}
	}

	& .miniatura-icone-aa {
		border-right: 1px solid $gray3;
	}


	& .miniatura-icone-ab {
	}

	& .miniatura-icone-ac {
		border-left: 1px solid $gray3;
	}


	& .miniatura-icone-ba {
		border-right: 1px solid $gray3;
	}

	& .miniatura-icone-bb {
	}

	& .miniatura-icone-bc {
		border-left: 1px solid $gray3;
	}

	& .miniatura-border{
		border-left: 1px solid $gray3;
		border-right: 1px solid $gray3;
	}

	& .miniatura-border-all{
		display: block;
		width: 100%;
		height: 1px;
		background-color: $gray3;
	}

	& .miniatura-informacoes {
		text-align: center;
		padding: .5rem;
		@include border-box;

		& .left {
			color: $gray1;
			font-size: 1.2rem;
		}

		& .right {
			color: $gray1;
			font-size: .8rem;
			letter-spacing: -.09rem;
			font-weight: 300;
		}
	}

	& .miniatura-p {
		color: $gray1;
		font-size: 1.1rem;
	}

	& hr {
		margin: 0;
	}

	& .miniatura-icone-central {
		border-left: 1px solid $gray1;
		border-right: 1px solid $gray1;
	}

	& .miniatura-imovel-detalhe{
		display: block;
		width: 100%;
		padding: 1rem;
		min-height: 23.6rem;
		background-color: white;
		//padding-bottom: .4rem;
		//background-color: $gray5;
		border-radius: 0 0 #{$padraoborderRadius} #{$padraoborderRadius};
		@include border-box;
		border: .1rem solid #ffffff;
		border-top: 0;
		position: relative;
		@include box-shadow('0 0 10px 5px rgba(0,0,0,.1)');

		& .miniatural-imovel-preco {
			display: block;
			width: 100%;
			padding: .5rem;
			padding-top: .7rem;
			color: #ffffff;
			text-align: center;
			@include border-box;
			background-color: $colorTerciary;
			@include border-radius($padraoborderRadius);
			font-size: 1rem;

			&:hover, &:active, &:focus, &:visited{
				color: #ffffff;
			}
		}

		& .relative {
			
			& .miniatura-marker {
				display: inline-block;
				width: 1.7rem;
				vertical-align: middle;
				
				& img {
					display: block;
					width: 100%;
				}
			}
			
			& .miniatura-marker-after::after{
				display: block;
				position: absolute;
				left: .8rem;
				margin-top: 0;
				width: .1rem;
				bottom: -15%;
				height: 95%;
				background-color: $colorPrimary;
				content: '';
			}
			& .miniatura-marker-after::before{
				display: block;
				position: absolute;
				left: .6rem;
				width: .5rem;
				height: .5rem;
				@include border-radius(50%);
				bottom: -15%;
				background-color: $colorPrimary;
				content: '';
			}
		}
		
		& .miniatura-cidade-estado{
			display: inline-block;
			width: calc(100% - 2rem);
			vertical-align: middle;
			font-size: .9rem;
			overflow: hidden;
			height: .8rem;
			font-weight: 600;
			color: $gray1;
		}

		& .miniatura-descricao-simples {
			display: inline-block;
			width: calc(100% - 2rem);
			overflow: hidden;
			height: 2.8rem;
			font-weight: 400 !important;
			font-size: 1rem;
		}

		& .imovel-interno-btnfake-miniatura {
		//	@extend .imovel-interno-btnfake;
			background-color: $colorPrimary !important;
			padding: .2rem 1rem !important;
			padding-bottom: .1rem !important;
			margin: 0;

			&:hover {
				background-color: #ffffff !important;
				color: $gray1;
				border: 1px solid $colorPrimary;
			}
		}

		& .miniatura-container-btns {
			display: block;
			margin-left: 1.8rem;
			width: calc(100% - 2rem);
		}

		& .btn-maisinformacoes {
			text-transform: lowercase;
		    height: 1.6rem;
		    line-height: 1.5rem;
		    padding-bottom: 1.6rem;
			font-weight: 600;
			margin: 0;
			font-size: .73rem;
			padding-left: 1.2rem;
			padding-right: 1.2rem;
			@include border-radius(#{$padraoborderRadius});
			&:hover{
				margin: 0 !important;
				font-size: .73rem !important;
			    height: 1.6rem !important;
			    line-height: 1.5rem !important;
			    padding-bottom: 1.6rem !important;
			}
		}

		& .btn-contatar {
			text-transform: lowercase;
		    height: 1.6rem;
		    line-height: 1.5rem;
		    padding-bottom: 1.6rem;
			font-weight: 600;
			margin: 0;
			font-size: .73rem;
			padding-left: 1.2rem;
			padding-right: 1.2rem;
			@include border-radius(#{$padraoborderRadius});

			&:hover{
				margin: 0 !important;
				font-size: .73rem !important;
			    height: 1.6rem !important;
			    line-height: 1.5rem !important;
			    padding-bottom: 1.6rem !important;
			}
		}

		/*& .miniatura-btn-favorito {
			position: absolute;
			top: 1rem;
			right: 1rem;
		}

		& .miniatura-imovel-btns-icons{
			display: block;
			width: 100%;
			overflow: hidden;
			min-height: 22px;
			height: 2.4rem;

			& .miniatura-imovel-icone {
				display: inline-block;
				width: 25%;
				vertical-align: middle;
			}

			& .miniatura-informacoes{

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}*/
	}
}

/* SÓ SE APLICA NO MOBILE */
@media all and (max-width: 1024px) {

	.miniatura-imovel {
		& .miniatura-imovel-detalhe{
			box-shadow: none !important;
			& .miniatura-marker-after::after{
				height: 7rem;
				left: 1.8rem;
			}

			& .miniatura-marker-after::before{
				bottom: 0.8rem;
				left: 1.6rem;
			}
		}
		& .miniatura-imovel-img{
			box-shadow: none !important;
		}
		
		& .miniatura-descricao-simples {
			& p {
				margin-top: 11px !important;
			}
		}
	}
}




/* SÓ SE APLICA NO MOBILE */
@media all and (max-width: 600px) {

	.miniatura-imovel {
		& .miniatura-imovel-detalhe{
			& .miniatura-marker-after::after{
				height: 27%;
			}

			& .miniatura-marker-after::before{
				bottom: 0.8rem;
			}
		}
	}
}


/* SÓ SE APLICA NO PC */
@media all and (min-width: 1024px) {
	.iwidth-100{
		width: 100% !important;
	}
	.iwidth-50{
		width: 50% !important;
	}
	.iwidth-33-3{
		width: 33.333333% !important;
	}
	.iwidth-25{
		width: 25% !important;
	}
	.iwidth-20{
		width: 20% !important;
	}
}
